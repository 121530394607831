import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faGithub, faLinkedin, faEthereum } from '@fortawesome/free-brands-svg-icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  min-height: 100vh;
  background-color: #121212;  
  color: #f5f5f5;  
`;

const Header = styled.h1`
  font-size: 2.5em;  
  color: #e1e1e1;  
  margin-bottom: 20px;  
`;

const Image = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 20px 0;
`;

const Description = styled.p`
  font-size: 20px;
  text-align: center;
  max-width: 800px;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 30px;
`;

const SocialLink = styled.a`
  font-size: 30px;
  color: #f5f5f5;  // Neon pink color for all social links
  transition: color 0.3s ease;  

  &:hover {
    color: #fff;  
  }
`;

function App() {
  return (
    <Container>
      <Header>Paul Cowgill</Header>
      <Image src="PaulCartoon.png" alt="Paul Cowgill Cartoon" />
      <Description>
        entrepreneur, product leader, and engineer.
      </Description>
      <SocialLinks>
        <SocialLink href="https://twitter.com/paulcowgill"><FontAwesomeIcon icon={faTwitter} /></SocialLink>
        <SocialLink href="https://github.com/pcowgill"><FontAwesomeIcon icon={faGithub} /></SocialLink>
        <SocialLink href="https://linkedin.com/in/pcowgill"><FontAwesomeIcon icon={faLinkedin} /></SocialLink>
        <SocialLink href="https://app.ens.domains/paulcowgill.eth?tab=subnames"><FontAwesomeIcon icon={faEthereum} /></SocialLink>
      </SocialLinks>
    </Container>
  );
}

export default App;
